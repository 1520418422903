<template>
  <vs-card>
    <div slot="header">
      <vs-row vs-type="flex" vs-align="space-around" vs-w="12">
        <vs-col vs-w="6">
          <h5 class="pt-2">Edit Discount</h5>
        </vs-col>
        <vs-col vs-w="6" class="flex justify-end">
          <vs-button
            class="py-2 px-4 mr-2"
            @click="onSave()">Save</vs-button>
          <vs-button
            class="py-2 px-4"
            @click="goToList" type="border">Cancel
          </vs-button>
        </vs-col>
      </vs-row>
    </div>

    <discount-details
      :details="details"
      :saving="isSave"
      @handleSave="handleSave">
    </discount-details>
  </vs-card>
</template>

<script>
import { mapActions } from "vuex";
import DiscountDetails from "@/views/components/WebStore/discount/Details.vue"

export default {
  name: 'EditDiscount',
  components: {
    DiscountDetails
  },
  data() {
    return {
      id: "",
      details: {
        discount_name: "",
        clinic_is_equal: false,
        product_is_equal: false,
        rule_valid_from: "",
        rule_valid_to: "",
        isTierCosting: false,
        discount_rules: [],
        products: [],
        clinics: [],
        group: null,
        groupId: null
      },
      isSave: false
    }
  },
  methods: {
    ...mapActions('discount', [
      "getBulkDiscountDetails",
      "updateBulkDiscount"
    ]),
    onSave() {
      this.isSave = true

      setTimeout(() => {
        this.isSave = false
      }, 1000)
    },
    async getDetails() {
      try {
        const details = await this.getBulkDiscountDetails(this.id)

        this.details = {
          group: null,
          groupId: null,
          ...details.data.data
        }
      } catch (err) {
        console.log('Error fetching details :: ', err.message)
      }
    },
    async handleSave(data) {
      this.$vs.loading();

      try {
        await this.updateBulkDiscount({
          id: this.id,
          body: data
        })

        this.$vs.notify({
          title: "Success",
          text: "Discount updated successfully",
          color: "success",
        });

        this.$vs.loading.close();

        this.goToList()
      } catch (err) {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update discount.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    goToList() {
      this.$router.push({ name: 'superAdminStoreDiscount' });
    }
  },
  created() {
    const { discountId } = this.$route.params

    this.id = discountId

    setTimeout(() => {
      this.getDetails()
    }, 500)
  }
}
</script>